/* ApprovalStatus.css */

.approval-status-container {
    max-width: 600px;
    margin: 0 auto;
    padding: 40px;
    text-align: center;
    background-color: #fff;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .approval-status-container h1 {
    font-size: 32px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .status-indicator {
    margin-bottom: 20px;
  }
  
  .status {
    font-weight: bold;
    color: #ff416c; /* You can adjust the color to fit your theme */
  }
  
  .status-explanation p {
    font-size: 18px;
    color: #666;
    margin-bottom: 30px;
  }
  
  .support-info p {
    font-size: 16px;
    color: #333;
  }
  
  .support-info a {
    color: #ff416c; /* Matching the theme color */
    text-decoration: underline;
  }
  