/* General Styles */
body {
  font-family: 'Roboto', sans-serif;
  background: #f7f7f7;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #333;
}

/* Container Styling */
.container {
  display: flex;
  width: 900px;
  height: 450px;
  margin: 50px auto;
  background-color: #fff;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  border-radius: 15px;
  overflow: hidden;
}

/* General Input Field Styling */
.form-input {
  width: 100%;
  padding: 12px;
  border: none;  /* Remove default border */
  border-radius: 25px;  /* Rounded corners */
  background-color: #f3f3f3;  /* Light grey background color */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);  /* Subtle inner shadow */
  font-size: 16px;
  color: #333;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* Ensure placeholder text is consistent */
.form-input::placeholder {
  color: #aaa;
  font-size: 14px;
}

/* Form and Section Styling */
.login-section {
  flex: 1;
  padding: 40px;
  background-color: #f7f7f7;
}

.login-form h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
}

.form-group {
  margin-bottom: 20px;
}

.login-form label {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  color: #666;
}

/* General Input Field Styling (applies to both login and signup forms) */
.login-form input[type="text"],
.login-form input[type="password"],
.signup-form input[type="email"],
.signup-form input[type="password"],
.signup-form input[type="text"] {
  width: 100%;
  padding: 12px;
  border: none;  /* Remove default border */
  border-radius: 25px;  /* Rounded corners */
  background-color: #f3f3f3;  /* Light grey background color */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);  /* Subtle inner shadow */
  font-size: 16px;
  color: #333;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.login-form input[type="text"]::placeholder,
.login-form input[type="password"]::placeholder,
.signup-form input[type="email"]::placeholder,
.signup-form input[type="password"]::placeholder,
.signup-form input[type="text"]::placeholder {
  color: #aaa;  /* Light grey placeholder text */
  padding-left: 10px; /* Add padding to the left side */
  font-size: 14px; /* Reduce font size of the placeholder text */
}

.login-form input[type="text"]:focus,
.login-form input[type="password"]:focus,
.signup-form input[type="email"]:focus,
.signup-form input[type="password"]:focus,
.signup-form input[type="text"]:focus {
  background-color: #e9e9e9;  /* Slightly darker background on focus */
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15);  /* Stronger shadow on focus */
}

/* Password container for the eye icon */
.password-container {
  position: relative;
  width: 100%;
}

.password-container input {
  width: 100%;
  padding-right: 40px; /* Ensure space for the icon */
  border: none;
  border-radius: 25px;
  background-color: #f3f3f3;  /* Light grey background color */
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12);  /* Subtle inner shadow */
  font-size: 16px;
  color: #333;
  outline: none;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.password-container input:focus {
  background-color: #e9e9e9;  /* Slightly darker background on focus */
  box-shadow: inset 0 2px 5px rgba(0, 0, 0, 0.15);  /* Stronger shadow on focus */
}

.toggle-password {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  color: #888;
}

.toggle-password:hover {
  color: #333;
}

/* Button Styling (applies to both login and signup forms) */
.sign-in-button,
.sign-up-button {
  width: 100%;
  padding: 12px;
  background-color: #ff416c;
  background-image: linear-gradient(to right, #ff4b2b, #ff416c);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
  margin-top: 10px; /* Reduced margin to bring the button closer to the input field */
}

.sign-in-button:hover,
.sign-up-button:hover {
  background-color: #ff4b2b;
}



/* Form Footer Styling */
.form-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  position: relative;
  z-index: 1;
}

.remember-me {
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.remember-me input[type="checkbox"] {
  appearance: none; /* Remove default checkbox styling */
  width: 14px;
  height: 14px;
  margin-right: 6px;
  border: 2px solid #ff416c; /* Border color to match the label */
  border-radius: 2px;
  background-color: transparent; /* Initial background color */
  cursor: pointer;
  position: relative;
}

.remember-me input[type="checkbox"]:checked {
  background-color: #ff416c; /* Background color when checked */
  border-color: #ff416c; /* Keep the border color the same */
}

.remember-me input[type="checkbox"]:checked::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate the checkmark */
}

.remember-me label {
  color: #ff416c;
  font-size: 14px;
  cursor: pointer;
  margin: 0; /* Ensure no additional margin */
}

.forgot-password {
  color: #888;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}


.remember-me label {
  color: #ff416c;
  font-size: 14px;
  cursor: pointer;
  margin: 0; /* Ensure no additional margin */
}

.forgot-password {
  color: #888;
  text-decoration: none;
  font-size: 14px;
}

.forgot-password:hover {
  text-decoration: underline;
}

/* Right Section Styling */
.welcome-section {
  flex: 1;
  padding: 40px;
  background-color: #ff416c;
  background-image: linear-gradient(to right, #ff4b2b, #ff416c);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.welcome-section h2 {
  font-size: 28px;
  margin-bottom: 20px;
  font-weight: 700;
}

.welcome-section p {
  font-size: 18px;
  margin-bottom: 30px;
}

.signup-button {
  padding: 12px 30px;
  background-color: transparent;
  color: white;
  border: 2px solid white;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.signup-button:hover {
  background-color: white;
  color: #ff416c;
}

/* Updated styling for error messages */
.error-message {
  color: red;
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.5;
  min-height: 40px; /* Reserve space for at least two lines */
  overflow-wrap: break-word; /* Break long words */
  word-wrap: break-word; /* Break long words */
  word-break: break-word; /* Break long words */
  overflow: auto; /* Ensure content doesn't overflow */
}

.status-indicator .status {
  display: inline-flex;
  align-items: center;
}

.status-indicator .status i {
  margin-right: 8px; /* Adjust this value to increase or decrease the gap */
}

.status.approved {
  color: #28a745; /* Green */
}

.status.rejected {
  color: #dc3545; /* Red */
}

.status.pending {
  color: #ffc107; /* Yellow */
}

.status i {
  margin-right: 8px; /* Adding the gap as previously discussed */
}

.status-indicator {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.logout-button {
  padding: 12px 20px;
  background-color: #ff416c;
  background-image: linear-gradient(to right, #ff4b2b, #ff416c);
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease, color 0.3s ease;
  margin-top: 20px; /* Add some space above the button */
}

.logout-button:hover {
  background-color: #ff4b2b;
}


.forgot-password-form {
  max-width: 400px;
  margin: 0 auto;
  padding: 40px;
  background-color: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  text-align: center;
}

.forgot-password-form h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.forgot-password-form .form-group {
  margin-bottom: 20px;
}

.forgot-password-form .reset-button {
  width: 100%;
  padding: 12px;
  background-color: #ff416c;
  color: white;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.forgot-password-form .reset-button:hover {
  background-color: #ff4b2b;
}

.success-message {
  color: green;
  margin-top: 20px;
}

.error-message {
  color: red;
  margin-top: 20px;
}

.back-button {
  margin-top: 20px;
  background: none;
  border: none;
  color: #ff416c;
  cursor: pointer;
  text-decoration: underline;
  font-size: 14px;
}
