/* Thumbnail Image */
.fullscreen-image-thumbnail {
  max-width: 100%;
  border-radius: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.fullscreen-image-thumbnail:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
}

/* Fullscreen Image Styling */
:fullscreen {
  width: 100%;
  height: 100%;
  object-fit: contain; /* Maintain aspect ratio */
  background-color: black; /* Black background during fullscreen */
}
