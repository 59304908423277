.content-card {
  background: white;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
  padding: 20px 30px;
  margin-bottom: 30px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.content-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
}

/* Styled Bullet Points */
.content-body ul {
  list-style: none; /* Remove default browser bullets */
  padding-left: 20px;
  margin-top: 10px;
}

.content-body ul li {
  position: relative;
  margin-bottom: 10px;
  padding-left: 25px; /* Create space for the custom bullet */
  font-size: 1rem;
  line-height: 1.6;
  color: #555;
}

/* Custom bullet symbol */
.content-body ul li::before {
  content: "•"; /* Unicode for a bullet */
  position: absolute;
  left: 0; /* Position it at the start of the list item */
  top: 0;
  color: #ff416c; /* Custom bullet color */
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1;
}


.content-image {
  max-width: 100%;
  border-radius: 10px;
  margin-top: 20px;
}



.note-box {
  background: #e6f7ff;
  border-left: 5px solid #007bff;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.warning-box {
  background: #fff4e6;
  border-left: 5px solid #ff9800;
  padding: 10px 15px;
  border-radius: 5px;
  margin-top: 20px;
}

.callout-icon {
  margin-right: 10px;
  vertical-align: middle;
}


/* Highlight Callout Sections */
.content-body .highlight {
  background: #f9f9f9;
  border-left: 4px solid #007bff;
  padding: 15px 20px;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.content-body .highlight h3 {
  color: #007bff;
  font-size: 1.2rem;
  margin-bottom: 10px;
}


.note-box, .warning-box {
  font-size: 0.9rem;
  line-height: 1.5;
  margin-top: 20px;
}

h3 {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 10px;
}




