body {
  font-family: 'Poppins', sans-serif;
  background: linear-gradient(135deg, #f7f9fc, #ffffff);
  color: #333;
  margin: 0;
  padding: 0;
}

.documentation-container {
  display: flex;
  width: 100vw;
  height: 100vh;
}

.main-content {
  flex: 1;
  margin-left: 260px; /* Fixed width for sidebar */
  padding: 30px 40px;
  box-sizing: border-box;
  overflow-y: auto;
}

.button:hover {
  background: #ff2a1b;
  transform: translateY(-2px);
}


.divider {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  margin: 40px 0;
  position: relative;
}

.divider::before {
  content: "★";
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
  background: white;
  padding: 0 10px;
  color: #ff4b2b;
  font-size: 1.5rem;
}


/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.section {
  animation: fadeIn 0.6s ease;
}


