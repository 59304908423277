.faq-section {
  margin-top: 60px;
  padding: 30px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.1);
}

.faq-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.faq-item {
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  background: #ff4b2b;
  color: white;
  font-size: 1.1rem;
  cursor: pointer;
}

.faq-header:hover {
  background: #e54329;
}

.faq-icon {
  font-size: 1.5rem;
  font-weight: bold;
}

.faq-content {
  padding: 15px 20px;
  background: #f7f9fc;
  color: #333;
  line-height: 1.6;
}
