.tutorials-section {
    margin-top: 40px;
    padding: 20px;
  }
  
  .tutorials-title {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .tutorials-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .tutorial-card {
    background: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .tutorial-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  }
  
  .tutorial-video {
    width: 100%;
    height: 200px;
    border-radius: 10px;
  }
  
  .tutorial-title {
    margin-top: 10px;
    font-size: 1.2rem;
    color: #333;
  }
  