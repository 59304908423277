/* Overall Layout */
.dashboard-layout {
  display: grid;
  grid-template-rows: 64px auto;
  height: 100vh;
  width: 100vw;
}

/* Header */
.header {
  background-color: #001529;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px 30px 40px;
  color: white;
  font-size: 20px;
  font-weight: bold;
}

.empty-space {
  padding: 0px 10px;
}


/* Main Content */
.content {
  padding: 40px;
  background-color: #f0f2f5;
  overflow-y: auto;
}

/* Search Bar */

/* Search Bar and Dropdown Row */
.search-row {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
}

/* Search Bar */
.search-row .ant-input {
  height: 40px;
  border-radius: 8px;
  padding: 0 12px;
  font-size: 16px;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.search-row .ant-input:focus {
  border-color: #ff416c;
  box-shadow: 0 0 8px rgba(24, 144, 255, 0.4);
}

/* Dropdown (Select) */
.search-row .ant-select {
  width: 200px;
  height: 40px;
}

.search-row .ant-select-selector {
  height: 40px !important;
  border-radius: 8px !important;
  display: flex;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: box-shadow 0.3s ease, border-color 0.3s ease;
}

.search-row .ant-select-selector:focus,
.search-row .ant-select-selector:hover {
  border-color: #ff416c !important;
  box-shadow: 0 0 8px rgba(24, 144, 255, 0.4);
}

.search-row .ant-select-arrow {
  color: #ff416c;
}

/* Tabs */
.ant-tabs-nav-list {
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  font-size: 18px;
  font-weight: 600;
}

/* Table */
.ant-table {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

/* Center Align Pagination */
.ant-pagination {
  display: flex;
  justify-content: center; /* Center alignment */
  margin-top: 20px; /* Add spacing above the pagination */
}

/* Rounded Corners and Hover Effects */
.ant-pagination-item,
.ant-pagination-prev,
.ant-pagination-next {
  border-radius: 8px; /* Rounded corners */
  transition: border-color 0.3s ease, background-color 0.3s ease;
}

.ant-pagination-item:hover,
.ant-pagination-prev:hover,
.ant-pagination-next:hover {
  border-color: #ff416c;
}

/* Active Page Styling */
.ant-pagination-item-active {
  background-color: #ff416c;
  border-color: #ff416c;
  color: #fff;
}

/* Dropdown Styling for Page Size Options */
.ant-select-dropdown {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-item:hover {
  background-color: #f5f5f5;
}

/* Size Dropdown Styling */
.ant-select-dropdown {
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ant-select-item:hover {
  background-color: #f5f5f5;
}


/* Buttons */
.ant-btn-primary {
  background-color: #ff416c;
  border-color: #ff416c;
  font-size: 16px;
}

.ant-btn-danger {
  background-color: #ff4d4f;
  border-color: #ff4d4f;
  font-size: 16px;
}
