.marketplace-tabs {
  border: 1px solid #ddd;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.tabs {
  display: flex;
  background: #f4f4f4;
  border-bottom: 1px solid #ddd;
}

.tab-button {
  flex: 1;
  padding: 10px 15px;
  cursor: pointer;
  background: transparent;
  border: none;
  font-size: 1rem;
  text-align: center;
  transition: background 0.3s ease, color 0.3s ease;
  color: #666; /* Default inactive tab color */
}

.tab-button.active {
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
  color: white;
  font-weight: bold;
  border-bottom: 2px solid transparent;
}

.tab-button:hover {
  background: rgba(255, 75, 43, 0.1); /* Subtle hover effect */
  color: #333; /* Ensure hover readability */
}

.tab-content {
  padding: 20px;
  text-align: center;
}

.tab-content h3 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 10px;
}

.tab-content p {
  margin-bottom: 20px;
  font-size: 1rem;
  line-height: 1.5;
  color: #666;
}

.download-button {
  display: inline-block;
  padding: 10px 20px;
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
  color: white;
  text-decoration: none;
  border-radius: 5px;
  font-size: 1rem;
  transition: background 0.3s ease;
}

.download-button:hover {
  background: linear-gradient(135deg, #ff416c, #ff4b2b);
}

/* Add additional spacing for tabs */
.marketplace-tabs {
  margin-top: 20px; /* Create space between the previous element (e.g., image) and the tabs */
  margin-bottom: 20px;
}

.instructions {
  text-align: left;
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.instructions ol {
  padding-left: 20px;
}

.instructions li {
  margin-bottom: 10px;
}
