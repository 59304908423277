/* Sidebar */
.sidebar {
  width: 250px; /* Fixed width */
  background: linear-gradient(135deg, #ff4b2b, #ff416c); /* Restored gradient */
  color: white; /* White text for contrast */
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  border-right: none; /* Remove border to emphasize gradient */
  z-index: 500; /* Lower than Lightbox */
}

/* Sidebar Header */
.sidebar-header {
  position: sticky;
  top: 0;
  background: linear-gradient(135deg, #ff4b2b, #ff416c); /* Match gradient */
  z-index: 10;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3); /* Subtle divider */
}

.sidebar-title {
  font-size: 1.6rem;
  margin-bottom: 10px;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px rgba(0, 0, 0, 0.3); /* Slight shadow for emphasis */
}

/* Search Bar */
.search-bar {
  display: flex;
  align-items: center;
  background: rgba(255, 255, 255, 0.2); /* Semi-transparent white */
  padding: 5px 10px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.search-icon {
  margin-right: 5px;
  color: white; /* Icon matches text */
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  width: 100%;
  color: white; /* Match input text with sidebar */
  font-size: 0.9rem;
}

/* Sidebar Section Headers */
.sidebar-section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background: rgba(255, 255, 255, 0.1); /* Subtle contrast */
  font-weight: bold;
  cursor: pointer;
  border-radius: 5px;
  margin-bottom: 10px;
  transition: background 0.3s ease;
}

.sidebar-section-header:hover {
  background: rgba(255, 255, 255, 0.2);
}

/* Sidebar Items */
.sidebar-items {
  margin-bottom: 20px;
}

.tab {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 15px;
  border-radius: 5px;
  text-decoration: none;
  color: white;
  font-size: 0.9rem;
  transition: all 0.3s ease;
}

.tab:hover {
  background: rgba(255, 255, 255, 0.2);
}

.tab.active {
  background: rgba(255, 255, 255, 0.3);
  font-weight: bold;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4); /* Highlight effect */
}

.chevron {
  transition: transform 0.3s ease;
}

.chevron.open {
  transform: rotate(180deg);
}
