.hero-container {
  margin-left: 20px; /* Offset the hero section */
  margin-right: 20px; /* Optional symmetry */
}

.hero {
  background: linear-gradient(135deg, #ff4b2b, #ff416c);
  color: white;
  padding: 40px;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2); /* Retain depth */
  overflow: hidden;
  position: relative;
}

.hero h1 {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.hero p {
  font-size: 1.2rem;
  margin-top: 10px;
}

.hero::after,
.hero::before {
  content: '';
  position: absolute;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  z-index: 0;
}

.hero::after {
  width: 200px;
  height: 200px;
  top: -50px;
  right: -50px;
}

.hero::before {
  width: 150px;
  height: 150px;
  bottom: -50px;
  left: -50px;
}
